import { useEffect } from 'react'
import { useChainId } from 'wagmi'
import { ChainId } from '@uniswap/sdk-core'
import useSelectChain from './useSelectChain'
import { useSearchParams } from 'react-router-dom'

export function useInitialChainSetup () {
  const chainId = useChainId()
  const selectChain = useSelectChain()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const setInitialChain = async () => {
      const chainParam = searchParams.get('chain')
      if (!chainParam || chainId !== ChainId.ARBITRUM_ONE) {
        try {
          await selectChain(ChainId.ARBITRUM_ONE)
          if (!chainParam) {
            searchParams.set('chain', 'arbitrum')
            setSearchParams(searchParams, { replace: true })
          }
        } catch (error) {
          console.error('Failed to switch to Arbitrum:', error)
        }
      }
    }

    setInitialChain()
  }, [chainId, selectChain, searchParams, setSearchParams])
}
