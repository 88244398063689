import { InterfacePageName } from "@uniswap/analytics-events";
import { ChainId, Currency } from "@uniswap/sdk-core";
import { Trace } from "analytics";
import { NetworkAlert } from "components/NetworkAlert/NetworkAlert";
import { SwitchLocaleLink } from "components/SwitchLocaleLink";
import SwapHeader from "components/swap/SwapHeader";
import { SwapTab } from "components/swap/constants";
import { PageWrapper, SwapSection, SwapWrapper } from "components/swap/styled";
import { useSupportedChainId } from "constants/chains";
import { useScreenSize } from "hooks/useScreenSize";
import {
  ReactNode,
  useCallback,
  useState,
  useRef,
  KeyboardEvent,
  useEffect,
} from "react";
import { useLocation } from "react-router-dom";
import { InterfaceTrade, TradeState } from "state/routing/types";
import { isPreviewTrade } from "state/routing/utils";
import {
  SwapAndLimitContextProvider,
  SwapContextProvider,
} from "state/swap/SwapContext";
import { useInitialCurrencyState } from "state/swap/hooks";
import { CurrencyState, SwapAndLimitContext } from "state/swap/types";
import { useChainId } from "wagmi";
import { useIsDarkMode } from "../../theme/components/ThemeToggle";
import { SwapForm } from "./SwapForm";
import { useTokenData } from "state/assetData/hooks";

export function getIsReviewableQuote(
  trade: InterfaceTrade | undefined,
  tradeState: TradeState,
  swapInputError?: ReactNode
): boolean {
  if (swapInputError) return false;
  // if the current quote is a preview quote, allow the user to progress to the Swap review screen
  if (isPreviewTrade(trade)) return true;

  return Boolean(trade && tradeState === TradeState.VALID);
}

export default function SwapPage({ className }: { className?: string }) {
  const location = useLocation();

  const { initialInputCurrency, initialOutputCurrency, chainId } =
    useInitialCurrencyState();

  const shouldDisableTokenInputs =
    useSupportedChainId(useChainId()) === undefined;

  // TODO: Review this
  useTokenData(chainId);

  return (
    <Trace page={InterfacePageName.SWAP_PAGE} shouldLogImpression>
      <PageWrapper>
        <Swap
          className={className}
          chainId={chainId}
          disableTokenInputs={shouldDisableTokenInputs}
          initialInputCurrency={initialInputCurrency}
          initialOutputCurrency={initialOutputCurrency}
          syncTabToUrl={true}
        />
        <NetworkAlert />
      </PageWrapper>
      {location.pathname === "/swap" && <SwitchLocaleLink />}
    </Trace>
  );
}

/**
 * The swap component displays the swap interface, manages state for the swap, and triggers onchain swaps.
 *
 * In most cases, chainId should refer to the connected chain, i.e. `useWeb3React().chainId`.
 * However if this component is being used in a context that displays information from a different, unconnected
 * chain (e.g. the TDP), then chainId should refer to the unconnected chain.
 */
export function Swap({
  className,
  initialInputCurrency,
  initialOutputCurrency,
  chainId,
  onCurrencyChange,
  disableTokenInputs = false,
  compact = false,
  syncTabToUrl,
}: {
  className?: string;
  chainId?: ChainId;
  onCurrencyChange?: (selected: CurrencyState) => void;
  disableTokenInputs?: boolean;
  initialInputCurrency?: Currency;
  initialOutputCurrency?: Currency;
  compact?: boolean;
  syncTabToUrl: boolean;
}) {
  const isDark = useIsDarkMode();
  const screenSize = useScreenSize();

  return (
    <SwapAndLimitContextProvider
      chainId={chainId}
      initialInputCurrency={initialInputCurrency}
      initialOutputCurrency={initialOutputCurrency}
    >
      {/* TODO: Move SwapContextProvider inside Swap tab ONLY after SwapHeader removes references to trade / autoSlippage */}
      <SwapAndLimitContext.Consumer>
        {({ currentTab }) => (
          <SwapContextProvider>
            <SwapWrapper isDark={isDark} className={className} id="swap-page">
              <SwapHeader
                compact={compact || !screenSize.sm}
                syncTabToUrl={syncTabToUrl}
              />
              {currentTab === SwapTab.Swap && (
                <SwapForm
                  onCurrencyChange={onCurrencyChange}
                  disableTokenInputs={disableTokenInputs}
                />
              )}
              {/* Uncomment these when implemented
                  {currentTab === SwapTab.Limit && <LimitFormWrapper onCurrencyChange={onCurrencyChange} />}
                  {currentTab === SwapTab.Send && (
                    <SendForm disableTokenInputs={disableTokenInputs} onCurrencyChange={onCurrencyChange} />
                  )}
                  */}
            </SwapWrapper>
          </SwapContextProvider>
        )}
      </SwapAndLimitContext.Consumer>
    </SwapAndLimitContextProvider>
  );
}
