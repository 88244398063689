import { Currency, Percent, TradeType } from "@uniswap/sdk-core";
import Column, { AutoColumn } from "components/Column";
import { useUSDPrice } from "hooks/useUSDPrice";
import { Trans } from "i18n";
import { InterfaceTrade, TradeResults } from "state/routing/types";
import { isPreviewTrade } from "state/routing/utils";
import styled from "styled-components";
import { ThemedText } from "theme/components";

import { Field } from "../swap/constants";
import { SwapModalHeaderAmount } from "../swap/SwapModalHeaderAmount";

const HeaderContainer = styled(AutoColumn)`
  margin-top: 0px;
`;

export function ClosePreview({
  trade,
  inputCurrency,
  allowedSlippage,
  tradeResults,
}: {
  trade: InterfaceTrade;
  inputCurrency?: Currency;
  allowedSlippage: Percent;
  tradeResults: TradeResults;
}) {
  const fiatValueInput = useUSDPrice(tradeResults?.payAmount);
  const fiatValueOutput = useUSDPrice(tradeResults?.receiveAmount);

  return (
    <HeaderContainer gap="sm">
      <Column gap="lg">
        
        <SwapModalHeaderAmount
          field={Field.OUTPUT}
          label={<Trans>You redeem</Trans>}
          amount={tradeResults.receiveAmount}
          currency={trade.inputAmount.currency}
          otherCurrency={trade.outputAmount.currency}
        //   usdAmount={fiatValueOutput.data}
          isLoading={
            isPreviewTrade(trade) && trade.tradeType === TradeType.EXACT_INPUT
          }
          tooltipText={
            trade.tradeType === TradeType.EXACT_INPUT ? (
              <ThemedText.Caption>
                <Trans>
                  Output is estimated. You will receive at least{" "}
                  {{
                    amount: (
                      <b>
                        {trade
                          .minimumAmountOut(allowedSlippage)
                          .toSignificant(6)}{" "}
                        {trade.outputAmount.currency.symbol}
                      </b>
                    ),
                  }}{" "}
                  or the transaction will revert.
                </Trans>
              </ThemedText.Caption>
            ) : (
              <ThemedText.Caption>
                <Trans>
                  Input is estimated. You will redeem at most{" "}
                  {{
                    amount: (
                      <b>
                        {trade
                          .maximumAmountIn(allowedSlippage)
                          .toSignificant(6)}{" "}
                        {trade.inputAmount.currency.symbol}
                      </b>
                    ),
                  }}{" "}
                  or the transaction will revert.
                </Trans>
              </ThemedText.Caption>
            )
          }
        />
        <SwapModalHeaderAmount
          field={Field.INPUT}
          label={<Trans>You receive</Trans>}
          // TODO: This needs fixing, right now it's the total amount of collateral - we should display the amount of collateral being received by the user
          amount={tradeResults.receiveAmount}
          currency={inputCurrency ?? trade.inputAmount.currency}
          usdAmount={fiatValueInput.data}
          isLoading={
            isPreviewTrade(trade) && trade.tradeType === TradeType.EXACT_OUTPUT
          }
        />
      </Column>
    </HeaderContainer>
  );
}
