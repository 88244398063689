import { BigNumber } from 'ethers'
import { formatUnits, parseUnits } from 'ethers/lib/utils'

const STAKED_TOKEN_ADDRESS =
  '0x5979D7b546E38E414F7E9822514be443A4800529'.toLowerCase()

export function calculateApy (
  collateralToken: any,
  debtToken: any,
  position: any
): { collateralApy: number; debtApy: number; netUserApy: number } {
  // TODO: in the future put the parser formatUnits in a wrapper so if we change from
  // ether to viem then we don't need to change the code
  const collateralApy = Number(
    formatUnits(collateralToken.liquidityRate, 27) ?? 0
  )
  const debtApy = Number(formatUnits(debtToken.variableBorrowRate, 27) ?? 0)

  const earnedUSD = calculateEarnedUSD(position, collateralApy)
  const costUSD = calculateCostUSD(position, debtApy)

  const netEarnedResult = earnedUSD - costUSD

  //   const userProvidedAsset =
  //     Number(position.stats?.totalCollateralBase) /
  //     Number(position.stats?.leverage)
  const netUserApy =
    (netEarnedResult /
      (Number(position.stats?.totalCollateralBase) -
        Number(position.stats?.totalDebtBase))) *
    10

  return { collateralApy, debtApy, netUserApy }
}

function calculateEarnedUSD (position: any, collateralApy: number): number {
  if (position.collateral.toLowerCase() === STAKED_TOKEN_ADDRESS) {
    //TODO: this is a temporary value, need to get from the chain
    const earnedStakedUSD = Number(position.stats?.totalCollateralBase) * 0.0316
    return (
      earnedStakedUSD +
      Number(position.stats?.totalCollateralBase) * collateralApy
    )
  } else {
    return Number(position.stats?.totalCollateralBase) * collateralApy
  }
}

function calculateCostUSD (position: any, debtApy: number): number {
  if (position.debt.toLowerCase() === STAKED_TOKEN_ADDRESS) {
    //TODO: this is a temporary value, need to get from the chain
    const costDebtUSD = Number(position.stats?.totalDebtBase) * 0.0316
    return costDebtUSD + Number(position.stats?.totalDebtBase) * debtApy
  } else {
    return Number(position.stats?.totalDebtBase) * debtApy
  }
}

export function formatLeverage (leverage: BigNumber | undefined): string {
  if (leverage && !leverage.isZero()) {
    return parseFloat(formatUnits(leverage, 1)) + 'x'
  }
  return '-'
}

export function formatHealthFactor (
  healthFactor: BigNumber | undefined
): string {
  if (
    healthFactor &&
    !healthFactor.isZero() &&
    healthFactor.lt(BigNumber.from(10).pow(59))
  ) {
    return parseFloat(formatUnits(healthFactor, 18)).toFixed(2)
  }
  return '-'
}
