import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  KeyboardEvent,
} from "react";
import styled, { keyframes, css, useTheme } from "styled-components";
import { ButtonLight } from "components/Button";
import { SwapSection } from "components/swap/styled";
import { ThemedText } from "theme/components";
import { useToggleAccountDrawer } from "components/AccountDrawer/MiniPortfolio/hooks";
import { Trans } from "i18n";
import { Dots } from "components/swap/styled";
import { useAccount, useSignMessage } from "wagmi";
import Loader from "components/Icons/LoadingSpinner";
import { useUpdateAtom } from "jotai/utils";
import { walletAuthStatusAtom } from "state/application/atoms";
import { sendAnalyticsEvent } from "analytics";
import { InterfaceEventName } from "@uniswap/analytics-events";

const InviteCodeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 300px;
`;

const InviteInputWrapper = styled(SwapSection)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  width: 100%;
  flex-grow: 1;
  padding: 32px 16px;
`;

const InputsRow = styled.div`
  display: flex;
  gap: 12px;
  justify-content: center;
  width: 100%;
  margin: 24px 0;
`;

const shakeAnimation = keyframes`
  0% { transform: translateX(0); }
  25% { transform: translateX(5px); }
  50% { transform: translateX(-5px); }
  75% { transform: translateX(5px); }
  100% { transform: translateX(0); }
`;

const CodeInput = styled.input<{ $hasError: boolean }>`
  width: 48px;
  height: 64px;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  border: 1px solid
    ${({ theme, $hasError }) => ($hasError ? theme.critical : theme.surface3)};
  border-radius: 12px;
  background-color: ${({ theme }) => theme.surface2};
  color: ${({ theme }) => theme.neutral1};
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  ${({ $hasError }) =>
    $hasError &&
    css`
      animation: ${shakeAnimation} 0.2s ease-in-out;
    `}

  &:focus {
    outline: none;
    border-color: ${({ theme, $hasError }) =>
      $hasError ? theme.critical : theme.accent1};
    box-shadow: 0 0 0 1px
      ${({ theme, $hasError }) => ($hasError ? theme.critical : theme.accent1)};
  }
`;

// Add event name constants (you'll need to add these to @uniswap/analytics-events package)
const INVITE_CODE_EVENTS = {
  INVITE_CODE_VALIDATED: 'Invite Code Validated',
  INVITE_CODE_VALIDATION_FAILED: 'Invite Code Validation Failed',
  INVITE_CODE_SIGNED: 'Invite Code Signed',
  INVITE_CODE_SIGN_FAILED: 'Invite Code Sign Failed',
  INVITE_CODE_USED: 'Invite Code Used',
} as const;

export function InviteCodeInput() {
  const setWalletAuthStatus = useUpdateAtom(walletAuthStatusAtom);
  const [inviteCode, setInviteCode] = useState(["", "", "", "", "", ""]);
  const [error, setError] = useState<boolean>(false);
  const [isValidCode, setIsValidCode] = useState<boolean>(false);
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [buttonState, setButtonState] = useState<"Enter code" | "Connect" | "Sign">("Enter code");
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const errorTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { address } = useAccount();
  const { signMessageAsync } = useSignMessage();
  const toggleWalletDrawer = useToggleAccountDrawer();

  const validateCode = useCallback(async (code: string) => {
    if (code.length !== 6) return;

    setIsValidating(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_FIKA_API_ENDPOINT}/api/validate-invite`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ code }),
        }
      );

      const data = await response.json();

      if (data.isValid) {
        setIsValidCode(true);
        setButtonState("Connect");
        setError(false);
        sendAnalyticsEvent(INVITE_CODE_EVENTS.INVITE_CODE_VALIDATED, {
          code,
          success: true,
        });
      } else {
        showError();
        setIsValidCode(false);
        sendAnalyticsEvent(INVITE_CODE_EVENTS.INVITE_CODE_VALIDATION_FAILED, {
          code,
          error: 'Invalid code',
        });
      }
    } catch (error) {
      showError();
      setIsValidCode(false);
      sendAnalyticsEvent(INVITE_CODE_EVENTS.INVITE_CODE_VALIDATION_FAILED, {
        code,
        error: 'Network error',
      });
    } finally {
      setIsValidating(false);
    }
  }, []);

  const handleChange = useCallback(
    (index: number, value: string) => {
      if (value.length <= 1) {
        const newInviteCode = [...inviteCode];
        newInviteCode[index] = value.toUpperCase();
        setInviteCode(newInviteCode);

        if (value && index < 5) {
          inputRefs.current[index + 1]?.focus();
        }

        // Validate if all characters are filled or if we're changing a character when all 6 are filled
        if (
          newInviteCode.every((char) => char !== "") ||
          (inviteCode.every((char) => char !== "") && value !== "")
        ) {
          validateCode(newInviteCode.join(""));
        }
      }
    },
    [inviteCode, validateCode]
  );

  const handleSignAndUseInvite = useCallback(async () => {
    if (!address) return;

    const code = inviteCode.join("");
    const message = `I'm joining Fika Exchange Alpha Season with my\u00A0wallet, have been referred by ${code}, and I agree to the terms and conditions.\n\nWallet address:\n${address}`;

    try {
      sendAnalyticsEvent(INVITE_CODE_EVENTS.INVITE_CODE_SIGNED, {
        code,
        address,
      });

      const signature = await signMessageAsync({ message });

      const response = await fetch(
        `${process.env.REACT_APP_FIKA_API_ENDPOINT}/api/use-invite`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ code, address, signature }),
        }
      );
      const data = await response.json();

      if (data.success) {
        setWalletAuthStatus((prevStatus) => ({
          ...prevStatus,
          [address]: 1,
        }));
        sendAnalyticsEvent(INVITE_CODE_EVENTS.INVITE_CODE_USED, {
          code,
          address,
          success: true,
        });
      } else {
        showError();
        sendAnalyticsEvent(INVITE_CODE_EVENTS.INVITE_CODE_SIGN_FAILED, {
          code,
          address,
          error: 'API error',
        });
      }
    } catch (error) {
      console.error("Error signing message:", error);
      showError();
      sendAnalyticsEvent(INVITE_CODE_EVENTS.INVITE_CODE_SIGN_FAILED, {
        code,
        address,
        error: error instanceof Error ? error.message : 'Unknown error',
      });
    }
  }, [inviteCode, address, signMessageAsync, setWalletAuthStatus]);

  const handleKeyDown = (index: number, e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace" && !inviteCode[index] && index > 0) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const showError = () => {
    setError(true);
    if (errorTimeoutRef.current) {
      clearTimeout(errorTimeoutRef.current);
    }
    errorTimeoutRef.current = setTimeout(() => {
      setError(false);
    }, 2000);
  };

  useEffect(() => {
    if (address && isValidCode) {
      setButtonState("Sign");
    }
  }, [address, isValidCode]);

  const handleButtonClick = () => {
    switch (buttonState) {
      case "Connect":
        toggleWalletDrawer();
        break;
      case "Sign":
        handleSignAndUseInvite();
        break;
    }
  };

  return (
    <InviteCodeContainer>
      <InviteInputWrapper>
        <InputsRow>
          {inviteCode.map((digit, index) => (
            <CodeInput
              key={index}
              ref={(el) => (inputRefs.current[index] = el)}
              value={digit}
              onChange={(e) => handleChange(index, e.target.value)}
              onKeyDown={(e) => handleKeyDown(index, e)}
              maxLength={1}
              placeholder=""
              $hasError={error}
              disabled={isValidating}
            />
          ))}
        </InputsRow>
        <ThemedText.BodySmall>
          No invite code? Join the {""}
          <a
            href="https://x.com/FikaExchange"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            waitlist
          </a>
        </ThemedText.BodySmall>
      </InviteInputWrapper>

      <ButtonLight
        onClick={handleButtonClick}
        disabled={!isValidCode || isValidating}
      >
        {isValidating ? (
          <Dots>
            <Trans>Validating</Trans>
          </Dots>
        ) : (
          <Trans>{buttonState}</Trans>
        )}
      </ButtonLight>
    </InviteCodeContainer>
  );
}
