import { useCallback, useState } from 'react'
import { useContract } from './useContract'
import LekkerPoolAave_ABI from 'constants/contracts/abis/LekkerPoolAave.json'
import { useWeb3React } from '@web3-react/core'
import { BigNumber } from 'ethers'
import { ClassicTrade, InterfaceTrade } from 'state/routing/types'
import { encodePath } from 'utils/uniswapPaths'

export const useEditPosition = (positionAddress: string) => {
  const [error, setError] = useState<string | null>(null)
  const { account, chainId } = useWeb3React()
  const poolContract = useContract(positionAddress, LekkerPoolAave_ABI)

  const closePosition = useCallback(
    async (amount: string, trade: any) => {
      if (!chainId || !positionAddress || !account) {
        throw new Error('Invalid chain or missing account')
      }

      if (!poolContract) {
        throw new Error('Contract not initialized')
      }

      try {
        setError(null)
        
        const tx = await poolContract.removeFromPosition(
          amount,
          encodePath(
            trade.routes[0].path.map((a: { address: string }) => a.address),
            trade.routes[0].pools.map((a: { fee: number }) => a.fee)
          )
        )
        const receipt = await tx.wait()
        return receipt
      } catch (error) {
        setError(error instanceof Error ? error.message : 'An error occurred')
        console.log('Unable to close position', error)
      }
    },
    [chainId, positionAddress, account, poolContract]
  )

  return { closePosition, error }
}
