import React, { ReactElement, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { ApolloError } from '@apollo/client';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { ChainId, Currency, Token } from '@uniswap/sdk-core';
import { DoubleCurrencyAndChainLogo } from 'components/DoubleLogo';
import Row from 'components/Row';
import { Table } from 'components/Table';
import { Cell } from 'components/Table/Cell';
import { ClickableHeaderRow, HeaderArrow, HeaderSortText } from 'components/Table/styled';
import { NameText } from 'components/Tokens/TokenTable';
import { MAX_WIDTH_MEDIA_BREAKPOINT } from 'components/Tokens/constants';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ExternalLink, ThemedText } from 'theme/components';
import { useFormatter, NumberType } from 'utils/formatNumbers';
import { usePositionData, useAssetActions, useAssetState, useTokenData } from 'state/assetData/hooks';
import { AppState } from 'state/reducer';
import {  PositionData } from 'state/assetData/types';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { atomWithReset, useAtomValue, useResetAtom, useUpdateAtom } from 'jotai/utils';
import { OrderDirection, gqlToCurrency, unwrapToken } from 'graphql/data/util';
import { useToken } from 'hooks/Tokens';
import { generateTokenFromAddress } from 'constants/lists';
import { BigNumber } from 'ethers';
import { formatUnits } from 'ethers/lib/utils';
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'
import { useAtom } from 'jotai';
import { Trans } from 'i18n';
import { MouseoverTooltip } from 'components/Tooltip';
import { calculateApy, formatLeverage, formatHealthFactor } from 'utils/positionUtils';

export enum UserPositionSortFields {
  Ownership = 'Ownership',
  TotalCollateralBase = 'Total Collateral Base',
  TotalDebtBase = 'Total Debt Base',
  HealthFactor = 'Health Factor',
  Leverage = 'Leverage',
  APY = 'APY'
}

const HEADER_DESCRIPTIONS: Record<UserPositionSortFields, ReactNode | undefined> = {
  [UserPositionSortFields.Ownership]: undefined,
  [UserPositionSortFields.TotalCollateralBase]: undefined,
  [UserPositionSortFields.TotalDebtBase]: undefined,
  [UserPositionSortFields.HealthFactor]: undefined,
  [UserPositionSortFields.APY]: undefined,
  [UserPositionSortFields.Leverage]: (
    <Trans>
      1 day APR refers to the amount of trading fees relative to total value locked (TVL) within a pool. 1 day APR = 24H
      Fees / TVL
    </Trans>
  ),
}

const TableWrapper = styled.div`
  margin: 0 auto;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
`;

interface UserPositionsTableValues {
  index: number | null;
  positionDescription: ReactElement;
  lender: string | null;
  ownership: number | null;
  netValue: number;
  totalCollateralBase: number;
  totalDebtBase: number;
  healthFactor: BigNumber;
  leverage: BigNumber;
  apy: number;
}

export enum UserPositionsTableColumns {
  Index,
  PositionDescription,
  Lender,
  Ownership,
  Collateral,
  Debt,
  NetValue,
  HealthFactor,
  Leverage,
}

function PositionDescription({
  token0,
  token1,
  chainId,
  poolAddress
}: {
  token0: Token
  token1: Token
  chainId: ChainId
  poolAddress: string
}) {
  const currencies = [token0, token1]

  const explorerUrl = getExplorerLink(42161, poolAddress, ExplorerDataType.ADDRESS)

  return (
    <ExternalLink href={explorerUrl} target="_blank">
    <Row gap="sm">
      <DoubleCurrencyAndChainLogo chainId={chainId} currencies={currencies} size={28} />
      <NameText>
        {token0.symbol}/{token1.symbol}
      </NameText>
    </Row>
    </ExternalLink>

  )
}


// Used to keep track of sorting state for Pool Tables
// declared as atomWithReset because sortMethodAtom and sortAscendingAtom are shared across multiple Pool Table instances - want to be able to reset sorting state between instances
export const sortMethodAtom = atomWithReset<UserPositionSortFields>(UserPositionSortFields.Leverage)
export const sortAscendingAtom = atomWithReset<boolean>(false)

function useSetSortMethod(newSortMethod: UserPositionSortFields) {
  const [sortMethod, setSortMethod] = useAtom(sortMethodAtom)
  const setSortAscending = useUpdateAtom(sortAscendingAtom)

  return useCallback(() => {
    if (sortMethod === newSortMethod) {
      setSortAscending((sortAscending) => !sortAscending)
    } else {
      setSortMethod(newSortMethod)
      setSortAscending(false)
    }
  }, [sortMethod, setSortMethod, setSortAscending, newSortMethod])
}

const HEADER_TEXT: Record<UserPositionSortFields, ReactNode> = {
  [UserPositionSortFields.Ownership]: <Trans>Ownership</Trans>,
  [UserPositionSortFields.TotalCollateralBase]: <Trans>Collateral</Trans>,
  [UserPositionSortFields.TotalDebtBase]: <Trans>Debt</Trans>,
  [UserPositionSortFields.HealthFactor]: <Trans>Health Factor</Trans>,
  [UserPositionSortFields.Leverage]: <Trans>Leverage</Trans>,
  [UserPositionSortFields.APY]: <Trans>APY</Trans>,
}

function PositionTableHeader({
  category,
  isCurrentSortMethod,
  direction,
}: {
  category: UserPositionSortFields
  isCurrentSortMethod: boolean
  direction: OrderDirection
}) {
  const handleSortCategory = useSetSortMethod(category)
  return (
    <MouseoverTooltip disabled={!HEADER_DESCRIPTIONS[category]} text={HEADER_DESCRIPTIONS[category]} placement="top">
      <ClickableHeaderRow $justify="flex-end" onClick={handleSortCategory}>
        {isCurrentSortMethod && <HeaderArrow direction={direction} />}
        <HeaderSortText $active={isCurrentSortMethod}>{HEADER_TEXT[category]}</HeaderSortText>
      </ClickableHeaderRow>
    </MouseoverTooltip>
  )
}


export const UserTopPositionTable = React.memo(({ onPositionClick }: { onPositionClick: (position: PositionData) => void }) => {
  const dispatch = useAppDispatch();
  useTokenData(42161)
  useAssetActions(42161)

  const userPositions = useAppSelector((state) => state.assetState.positions[42161]);

  const loading = useAppSelector((state) => state.assetState.loading[42161] ?? true);

  const error = useAppSelector((state) => state.assetState.errors[42161]);
  


  const userPositionsFiltered = useMemo(() => {
    return userPositions?.filter((position: PositionData) => 
      position.userStats !== undefined && position.userStats !== null
    ) || [];
  }, [userPositions]);

  const memoizedUserPositionDataTable = useMemo(() => (
    <UserPositionDataTable
      positions={userPositionsFiltered}
      loading={loading}
      error={error}
      chainId={42161}
      maxWidth={1200}
      onPositionClick={onPositionClick}
    />
  ), [userPositionsFiltered, loading, error, onPositionClick]);

  return (
    <TableWrapper>
      {memoizedUserPositionDataTable}
    </TableWrapper>
  );
});


export const UserPositionDataTable = React.memo(({
  positions,
  loading,
  error,
  chainId,
  maxWidth,
  onPositionClick,
}: {
  positions?: PositionData[];
  loading: boolean;
  error?: any;
  chainId: number;
  maxWidth?: number;
  onPositionClick: (position: PositionData) => void;
}) => {

  const { formatFiatPrice, formatNumber, formatNumberOrString } = useFormatter();


  const tokens = useAppSelector((state) => state.assetState.tokens[42161]?.['Aave']);

  const positionsTableValues: UserPositionsTableValues[] | undefined = useMemo(
    () =>
      positions?.map((position, index) => {
        const positionSortRank = index + 1;
        

        const collateralToken = tokens[position.collateral];
        const debtToken = tokens[position.debt];

        const { netUserApy } = calculateApy(collateralToken, debtToken, position);
        
        const userSharesPercentage = BigNumber.from(position.userStats?.userShares || '0').toString();

      
        // Convert totalCollateralBase to BigNumber
        const userCollateral = Number(BigNumber.from(position.stats?.totalCollateralBase)
        .mul(userSharesPercentage)
        .div(BigNumber.from(10).pow(6)));

        const userDebt = Number(BigNumber.from(position.stats?.totalDebtBase)
        .mul(userSharesPercentage)
        .div(BigNumber.from(10).pow(6)));
          
        return {
          index: positionSortRank,
          positionDescription: (
            <PositionDescription
            token0={generateTokenFromAddress(chainId, position.collateral)}
            token1={generateTokenFromAddress(chainId, position.debt)}
              chainId={chainId}
              poolAddress={position.poolAddress}
            />
          ),
          lender: position.lender,
          ownership: (Number(position.userStats?.userShares)/100) ?? null,
          totalCollateralBase: userCollateral,
        totalDebtBase: userDebt,
        netValue: userCollateral - userDebt,
          healthFactor: BigNumber.from(position.stats?.healthFactor) ?? BigNumber.from(0),
          leverage: BigNumber.from(position.stats?.leverage) ?? BigNumber.from(0),
          apy: netUserApy,
        };
      }) ?? [],
    [positions, chainId]
  );

  const showLoadingSkeleton = loading || !!error;

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper<UserPositionsTableValues>();
    return [
      columnHelper.accessor((row) => row.index, {
        id: 'index',
        header: () => (
          <Cell justifyContent="center" minWidth={34}>
            <ThemedText.BodySecondary>#</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (index) => (
          <Cell justifyContent="center" loading={showLoadingSkeleton} minWidth={34}>
            <ThemedText.BodySecondary>{index.getValue?.()}</ThemedText.BodySecondary>
          </Cell>
        ),
      }),
      columnHelper.accessor((row) => row.positionDescription, {
        id: 'positionDescription',
        header: () => (
          <Cell justifyContent="flex-start" width={140} grow>
            <ThemedText.BodySecondary>Token Pair</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (positionDescription) => (
          <Cell justifyContent="flex-start" loading={showLoadingSkeleton} width={140} grow>
            <ThemedText.BodyPrimary>{positionDescription.getValue?.()}</ThemedText.BodyPrimary>
          </Cell>
        ),
      }),
   
      columnHelper.accessor((row) => row.leverage, {
        id: 'leverage',
        header: () => (
          <Cell justifyContent="flex-end" minWidth={100} grow>
            <ThemedText.BodySecondary>Leverage</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (leverage) => (
          <Cell justifyContent="flex-end" loading={showLoadingSkeleton} minWidth={100} grow>
            <ThemedText.BodyPrimary>{leverage.getValue && Number(leverage.getValue()) != 0 ?  parseFloat(formatUnits(leverage.getValue(), 1)) + "x" : '-'}</ThemedText.BodyPrimary>
          </Cell>
        ),
      }),

      columnHelper.accessor((row) => row.apy, {
        id: 'apy',
        header: () => (
          <Cell justifyContent="flex-end" minWidth={100} grow>
            <ThemedText.BodySecondary>APY</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (apy) => (
          // TODO: reivew APY multiplier
          <Cell justifyContent="flex-end" loading={showLoadingSkeleton} minWidth={100} grow> 
            <ThemedText.BodyPrimary>{apy.getValue?.() && Number(apy.getValue()) != 0 ? (apy.getValue()*10).toFixed(2) + '%' : '-'}</ThemedText.BodyPrimary>
          </Cell>
        ),
      }),

      columnHelper.accessor((row) => row.netValue, {
        id: 'netValue',
        header: () => (
          <Cell justifyContent="flex-end" minWidth={100} grow>
            <ThemedText.BodySecondary>Net Value</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (netValue) => (
          <Cell justifyContent="flex-end" loading={showLoadingSkeleton} minWidth={100} grow>
            <ThemedText.BodyPrimary>{netValue.getValue?.() && Number(netValue.getValue()) != 0 ? formatNumber({
                    input: parseFloat(formatUnits(netValue.getValue(), 8)),
                    type: NumberType.FiatTokenStats,
                  })
                : 'N/A'}</ThemedText.BodyPrimary>
          </Cell>
        ),
      }),
      
     
      columnHelper.accessor((row) => row.totalCollateralBase, {
        id: 'totalCollateralBase',
        header: () => (
          <Cell justifyContent="flex-end" minWidth={120} grow>
            <ThemedText.BodySecondary>Collateral</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (totalCollateralBase) => (
          <Cell justifyContent="flex-end" loading={showLoadingSkeleton} minWidth={120} grow>
            <ThemedText.BodyPrimary>
            {totalCollateralBase.getValue
                ? formatNumber({
                    input: parseFloat(formatUnits(totalCollateralBase.getValue(), 8)),
                    type: NumberType.FiatTokenStats,
                  })
                : 'N/A'}
            </ThemedText.BodyPrimary>
          </Cell>
        ),
      }),
      columnHelper.accessor((row) => row.totalDebtBase, {
        id: 'totalDebtBase',
        header: () => (
          <Cell justifyContent="flex-end" minWidth={120} grow>
            <ThemedText.BodySecondary>Debt</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (totalDebtBase) => (
          <Cell justifyContent="flex-end" loading={showLoadingSkeleton} minWidth={120} grow>
            <ThemedText.BodyPrimary>
            {totalDebtBase.getValue
                ? formatNumber({
                    input: parseFloat(formatUnits(totalDebtBase.getValue(), 8)),
                    type: NumberType.FiatTokenStats,
                  })
                : 'N/A'}            
            </ThemedText.BodyPrimary>
          </Cell>
        ),
      }),
      columnHelper.accessor((row) => row.healthFactor, {
        id: 'healthFactor',
        header: () => (
          <Cell justifyContent="flex-end" minWidth={120} grow>
            <ThemedText.BodySecondary>Health Factor</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (healthFactor) => (
          <Cell justifyContent="flex-end" loading={showLoadingSkeleton} minWidth={120} grow>
            <ThemedText.BodyPrimary>{healthFactor.getValue && Number(healthFactor.getValue()) != 0 && Number(healthFactor.getValue()) < 10 ** 59 ? parseFloat(formatUnits(healthFactor.getValue(), 18)).toFixed(2) : '-'}</ThemedText.BodyPrimary>
          </Cell>
        ),
      }),

      columnHelper.accessor((row) => row.ownership, {
        id: 'ownership',
        header: () => (
          <Cell justifyContent="flex-end" minWidth={100} grow>
            <ThemedText.BodySecondary>Ownership</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (ownership) => (
          <Cell justifyContent="flex-end" loading={showLoadingSkeleton} minWidth={100} grow>
            <ThemedText.BodyPrimary>{ownership.getValue?.() && Number(ownership.getValue()) != 0 ? (parseFloat(ownership.getValue()!.toString()) / 100).toFixed(0 )+ '%' : '-'}</ThemedText.BodyPrimary>
          </Cell>
        ),
      }),

      columnHelper.accessor((row) => row.lender, {
        id: 'lender',
        header: () => (
          <Cell justifyContent="center" width={120} grow>
            <ThemedText.BodySecondary>Market</ThemedText.BodySecondary>
          </Cell>
        ),
        cell: (lender) => (
          <Cell justifyContent="center" loading={showLoadingSkeleton} width={120} grow>
            <ThemedText.BodyPrimary>{lender.getValue?.()}</ThemedText.BodyPrimary>
          </Cell>
        ),
      }),
     
     
    ];
  }, [formatNumber, showLoadingSkeleton]);

  const handleRowClick = useCallback(
    (row: UserPositionsTableValues) => {
      console.log("Row clicked:", row);
     
        onPositionClick(row.positionDescription.props);
     
    },
    [positions, onPositionClick]
  );

  return (
    <Table
      columns={columns}
      data={positionsTableValues ?? []}
      loading={loading}
      error={error}
      maxWidth={maxWidth}
      onRowClick={handleRowClick}
    />
  );
});


