// LeverageSlider.tsx
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { SliderTooltip } from "./SliderTooltip";
import { opacify } from "theme/utils";
import { ChevronRight } from 'react-feather'; // Import the chevron icon


interface SliderProps {
  value: number;
  onChange: (value: number) => void;
  min: number;
  max: number;
}

const StyledSlider = styled(Slider)`
  width: 75%;
  margin: 32px;
  .rc-slider-rail {
    background-color: ${({ theme }) => opacify(0, theme.surface1)};
    height: 66px;
    margin-top: -33px;
  }

  .rc-slider-track {
    background-color: ${({ theme }) => opacify(0, theme.surface1)};
    height: 66px;
    margin-top: -33px;
  }

  .rc-slider-handle {
    --handle-height: 30px;
    height: var(--handle-height);
    width: calc(var(--handle-height) * 1.2);
    margin-top: calc(var(--handle-height) / -2);
    border: 4px solid ${({ theme }) => theme.surface1};
    opacity: 1;
    background-color: ${({ theme }) => theme.neutral3};
    border-radius: 10px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-weight: regular;
    user-select: none;
    &:active {
      box-shadow: none;
      border-color: ${({ theme }) => theme.surface1};
    }
    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
`;

const fadeInOut = keyframes`
  0%, 33.3%, 66.6%, 100% { opacity: 0; }
  16.65%, 50%, 83.3% { opacity: 1; }
`;

const SignalWrapper = styled.div`
  position: absolute;
  left: 100%;
  display: flex;
  align-items: center;
  margin-left: 4px;
  color: ${({ theme }) => theme.neutral2};
`;

const AnimatedChevron = styled(({ isVisible, ...rest }) => <ChevronRight {...rest} />)<{ delay: number; isVisible: boolean }>`
  opacity: 0;
  animation: ${fadeInOut} 3s linear;
  animation-delay: ${props => props.delay}s;
  visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
  margin-left: ${props => props.delay > 0 ? '-1px' : '0'};
`;

const HandleWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

// const SignalWrapper = styled.div`
//   position: absolute;
//   left: 100%;
//   display: flex;
//   align-items: center;
//   margin-left: 4px;
//   color: ${({ theme }) => theme.neutral2};
// `;


const SliderV2: React.FC<SliderProps> = ({ value, onChange, min, max }) => {
  const [showChevrons, setShowChevrons] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowChevrons(false);
    }, 5000); // 5 seconds for two complete cycles

    return () => clearTimeout(timer);
  }, []);

  return (
    <StyledSlider
      min={min}
      max={max}
      value={value}
      onChange={(e) => onChange(parseFloat(e.toString()))}
      step={0.1}
      handleRender={(renderProps: any) => {
        return (
          <HandleWrapper {...renderProps.props}>
          <SliderTooltip>{value}x</SliderTooltip>
          <SignalWrapper>
              <AnimatedChevron size={24} delay={1.2} isVisible={showChevrons} />
              <AnimatedChevron size={24} delay={1.3} isVisible={showChevrons} />
            </SignalWrapper>
        </HandleWrapper>
         
        );
      }}
    />
  );
};

export default SliderV2;
