import { ChainId } from '@uniswap/sdk-core'

export const CONTRACTS: any = {
  [ChainId.SEPOLIA]: {
    aavePool: '0x6Ae43d3271ff6888e7Fc43Fd7321a503ff738951',
    lekkerFactoryProxy: '0x6fDFe1701791e38bdDe726Ec5BFb8E894C558C42',
    lekkerFactory: '0x6fDFe1701791e38bdDe726Ec5BFb8E894C558C42',
    lekkerLens: '0xE6146fF36C2F6B3d16630D6D76Dbe4CB6D035750',
    uniswapOracle: '0x7Db3A46195F938cD536203A43B1636E54476Ad93'
  },
  [ChainId.ARBITRUM_ONE]: {
    // arbitrum mainnet

    // note: aaveDataProvider and aavePoolProvider is UiPoolDataProviderV3 provided by the Aave Protocol
    aaveDataProvider: '0xc0179321f0825c3e0F59Fe7Ca4E40557b97797a3',
    aavePoolAddressProvider: '0xa97684ead0e402dC232d5A977953DF7ECBaB3CDb',
    aavePool: '0x794a61358D6845594F94dc1DB02A252b5b4814aD',

    lekkerFactoryProxy: '0x530716ebF382E676D0FD01Dc30A57767cD3743bc',
    // note: address provided is the proxy
    lekkerFactory: '0x530716ebF382E676D0FD01Dc30A57767cD3743bc',
    lekkerLens: '0x3e1109dF9E214db0339AD2F4d1239Cf3d2aacd0a',
    Multicall: '0xcA11bde05977b3631167028862bE2a173976CA11' //CHECK IF CORRECT
  }
}
