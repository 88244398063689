import { QueryClientProvider } from '@tanstack/react-query'
import { CustomUserProperties, InterfaceEventName, WalletConnectionResult } from '@uniswap/analytics-events'
import { sendAnalyticsEvent, useTrace, user } from 'analytics'
import { recentConnectorIdAtom } from 'components/Web3Provider/constants'
import { queryClient, wagmiConfig } from 'components/Web3Provider/wagmi'
import { useIsSupportedChainId } from 'constants/chains'
import { RPC_PROVIDERS } from 'constants/providers'
import { useEthersWeb3Provider } from 'hooks/useEthersProvider'
import usePrevious from 'hooks/usePrevious'
import { useAtom } from 'jotai'
import { useUpdateAtom } from 'jotai/utils'
import { ReactNode, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useConnectedWallets } from 'state/wallets/hooks'
import { FeatureFlags } from 'uniswap/src/features/gating/flags'
import { useFeatureFlag } from 'uniswap/src/features/gating/hooks'
import { getCurrentPageFromLocation } from 'utils/urlRoutes'
import { getWalletMeta } from 'utils/walletMeta'
import { WagmiProvider, useAccount } from 'wagmi'
import { walletAuthStatusAtom } from 'state/application/atoms'

export default function Web3Provider({ children }: { children: ReactNode }) {
  const [walletAuthStatus, setWalletAuthStatus] = useAtom(walletAuthStatusAtom)
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <Updater setWalletAuthStatus={setWalletAuthStatus} />
        {children}
      </QueryClientProvider>
    </WagmiProvider>
  )
}

/** A component to run hooks under the Web3ReactProvider context. */
// TODO: remove this once we no longer need invite gate
// function Updater() {
function Updater({ setWalletAuthStatus }: { setWalletAuthStatus: (prevStatus: any) => any }) {
  const { address: account, chainId } = useAccount()
  const provider = useEthersWeb3Provider()

  const isSupportedChain = useIsSupportedChainId(chainId)
  const { connector } = useAccount()
  const { pathname } = useLocation()
  const currentPage = getCurrentPageFromLocation(pathname)
  const analyticsContext = useTrace()
  const networkProvider = isSupportedChain ? RPC_PROVIDERS[chainId] : undefined

  const updateRecentConnectorId = useUpdateAtom(recentConnectorIdAtom)
  useEffect(() => {
    if (connector) updateRecentConnectorId(connector.id)
  }, [connector, updateRecentConnectorId])

  // Trace RPC calls (for debugging).
  const shouldTrace = useFeatureFlag(FeatureFlags.TraceJsonRpc)
  useEffect(() => {
    if (shouldTrace) {
      provider?.on('debug', trace)
      if (provider !== networkProvider) {
        networkProvider?.on('debug', trace)
      }
    }
    return () => {
      provider?.off('debug', trace)
      networkProvider?.off('debug', trace)
    }
  }, [analyticsContext, networkProvider, provider, shouldTrace])

  const previousConnectedChainId = usePrevious(chainId)
  useEffect(() => {
    const chainChanged = previousConnectedChainId && previousConnectedChainId !== chainId
    if (chainChanged) {
      sendAnalyticsEvent(InterfaceEventName.CHAIN_CHANGED, {
        result: WalletConnectionResult.SUCCEEDED,
        wallet_address: account,
        wallet_type: connector?.name ?? 'Network',
        chain_id: chainId,
        previousConnectedChainId,
        page: currentPage,
      })
    }
  }, [account, chainId, connector, currentPage, previousConnectedChainId])

  // Send analytics events when the active account changes.
  const previousAccount = usePrevious(account)
  const [connectedWallets, addConnectedWallet] = useConnectedWallets()
  useEffect(() => {
    if (account && account !== previousAccount) {
      const walletType = connector?.name ?? 'Network'
      const peerWalletAgent = provider ? getWalletMeta(provider)?.agent : undefined

      const isReconnect = connectedWallets.some(
        (wallet) => wallet.account === account && wallet.walletType === walletType
      )

      provider
        ?.send('web3_clientVersion', [])
        .then((clientVersion) => {
          user.set(CustomUserProperties.WALLET_VERSION, clientVersion)
        })
        .catch((error) => {
          console.warn('Failed to get client version', error)
        })

      // User properties *must* be set before sending corresponding event properties,
      // so that the event contains the correct and up-to-date user properties.
      user.set(CustomUserProperties.WALLET_ADDRESS, account)
      user.postInsert(CustomUserProperties.ALL_WALLET_ADDRESSES_CONNECTED, account)

      user.set(CustomUserProperties.WALLET_TYPE, walletType)
      user.set(CustomUserProperties.PEER_WALLET_AGENT, peerWalletAgent ?? '')
      if (chainId) {
        user.set(CustomUserProperties.CHAIN_ID, chainId)
        user.postInsert(CustomUserProperties.ALL_WALLET_CHAIN_IDS, chainId)
      }

      sendAnalyticsEvent(InterfaceEventName.WALLET_CONNECTED, {
        result: WalletConnectionResult.SUCCEEDED,
        wallet_address: account,
        wallet_type: walletType,
        is_reconnect: isReconnect,
        peer_wallet_agent: peerWalletAgent,
        page: currentPage,
      })

      addConnectedWallet({ account, walletType })
    }
  }, [account, addConnectedWallet, currentPage, chainId, connectedWallets, connector, previousAccount, provider])

  useEffect(() => {
    const checkWalletAuthorization = async () => {
      if (account) {
        try {
          const response = await fetch(`${process.env.REACT_APP_FIKA_API_ENDPOINT}/api/validate-account?address=${account}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ address: account }),
          })

          if (!response.ok) {
            throw new Error('Network response was not ok')
          }

          const data = await response.json()
          
          setWalletAuthStatus((prevStatus: Record<string, number>) => ({
            ...prevStatus,
            [account]: data.isInvited == 1 ? 1 : 0
          }))
        } catch (error) {
          console.error('Error checking wallet validity:', error)
          setWalletAuthStatus((prevStatus: Record<string, number>) => ({
            ...prevStatus,
            [account]: 0
          }))
        }
      } else {
        // If no account is connected, we don't need to update anything
      }
    }

    checkWalletAuthorization()
  }, [account, setWalletAuthStatus])

  return null
}

function trace(event: any) {
  if (!event?.request) return
  const { method, id, params } = event.request
  console.groupCollapsed(method, id)
  console.debug(params)
  console.groupEnd()
}
