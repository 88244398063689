import { ethers } from 'ethers'

export function cutSelector (data: string): string {
  const hexPrefix = '0x'
  return hexPrefix + data.substring(hexPrefix.length + 8)
}

export function trim0x (bigNumber: bigint | string): string {
  const s = bigNumber.toString()
  if (s.startsWith('0x')) {
    return s.substring(2)
  }
  return s
}
export const constants = {
  ZERO_ADDRESS: '0x0000000000000000000000000000000000000000',
  EEE_ADDRESS: '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE',
  ZERO_BYTES32:
    '0x0000000000000000000000000000000000000000000000000000000000000000',
  MAX_UINT256: 2n ** 256n - 1n,
  MAX_INT256: 2n ** 255n - 1n,
  MAX_UINT48: 2n ** 48n - 1n,
  MIN_INT256: -(2n ** 255n),
  MAX_UINT128: 2n ** 128n - 1n,
  MAX_UINT32: 2n ** 32n - 1n,
  DEV_CHAINS: ['hardhat', 'localhost'] as string[]
} as const

/**
 * @category permit
 * @notice Compresses a permit function call to a shorter format based on its type.
 *   Type         | EIP-2612 | DAI | Permit2
 *   Uncompressed |    224   | 256 | 352
 *   Compressed   |    100   |  72 | 96
 * @param permit The full permit function call string.
 * @return A compressed permit string.
 */
export function compressPermit (permit: string): string {
  const abiCoder = new ethers.utils.AbiCoder()
  switch (permit.length) {
    case 450: {
      // TODO: needs testing
      // IERC20Permit.permit(address owner, address spender, uint value, uint deadline, uint8 v, bytes32 r, bytes32 s)
      const args = abiCoder.decode(
        [
          'address owner',
          'address spender',
          'uint256 value',
          'uint256 deadline',
          'uint8 v',
          'bytes32 r',
          'bytes32 s'
        ],
        permit
      )
      // Compact IERC20Permit.permit(uint256 value, uint32 deadline, uint256 r, uint256 vs)
      return (
        '0x' +
        args.value.toHexString().slice(2).padStart(64, '0') +
        (ethers.BigNumber.from(args.deadline).eq(constants.MAX_UINT256)
          ? '00000000'
          : // This needs fixing for ethers 5.7
            (args.deadline + 1n).toString(16).padStart(8, '0')) +
        BigInt(args.r).toString(16).padStart(64, '0') +
        (((args.v - 27n) << 255n) | BigInt(args.s))
          .toString(16)
          .padStart(64, '0')
      )
    }
    case 514: {
      // TODO: needs testing
      // IDaiLikePermit.permit(address holder, address spender, uint256 nonce, uint256 expiry, bool allowed, uint8 v, bytes32 r, bytes32 s)
      const args = abiCoder.decode(
        [
          'address holder',
          'address spender',
          'uint256 nonce',
          'uint256 expiry',
          'bool allowed',
          'uint8 v',
          'bytes32 r',
          'bytes32 s'
        ],
        permit
      )
      // Compact IDaiLikePermit.permit(uint32 nonce, uint32 expiry, uint256 r, uint256 vs)
      return (
        '0x' +
        args.nonce.toHexString().slice(2).padStart(8, '0') +
        (ethers.BigNumber.from(args.expiry).eq(constants.MAX_UINT256)
          ? '00000000'
          : ethers.BigNumber.from(args.expiration)
              .add(1)
              .toHexString()
              .slice(2)
              .padStart(8, '0')) +
        // This needs fixing for ethers 5.7
        BigInt(args.r).toString(16).padStart(64, '0') +
        (((args.v - 27n) << 255n) | BigInt(args.s))
          .toString(16)
          .padStart(64, '0')
      )
    }
    case 706: {
      // IPermit2.permit(address owner, PermitSingle calldata permitSingle, bytes calldata signature)
      const args = abiCoder.decode(
        [
          'address owner',
          'address token',
          'uint160 amount',
          'uint48 expiration',
          'uint48 nonce',
          'address spender',
          'uint256 sigDeadline',
          'bytes signature'
        ],
        permit
      )

      // Compact IPermit2.permit(uint160 amount, uint32 expiration, uint32 nonce, uint32 sigDeadline, uint256 r, uint256 vs)
      return (
        '0x' +
        ethers.BigNumber.from(args.amount)
          .toHexString()
          .slice(2)
          .padStart(40, '0') +
        (ethers.BigNumber.from(args.expiration).eq(constants.MAX_UINT48)
          ? '00000000'
          : ethers.BigNumber.from(args.expiration)
              .add(1)
              .toHexString()
              .slice(2)
              .padStart(8, '0')) +
        ethers.BigNumber.from(args.nonce)
          .toHexString()
          .slice(2)
          .padStart(8, '0') +
        (ethers.BigNumber.from(args.sigDeadline).eq(constants.MAX_UINT48)
          ? '00000000'
          : ethers.BigNumber.from(args.sigDeadline)
              .add(1)
              .toHexString()
              .slice(2)
              .padStart(8, '0')) +
        args.signature.slice(2).padStart(128, '0') // Assuming signature is a hex string
      )
    }
    case 202:
    case 146:
    case 194:
      throw new Error('Permit is already compressed')
    default:
      throw new Error('Invalid permit length')
  }
}
