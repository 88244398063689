import React, { useCallback, useMemo, useState } from 'react'
import { Trans } from 'i18n'
import { PositionData } from 'state/assetData/types'
import { useClosePosition } from 'hooks/usePositionActions'
import { ConfirmModalState } from '../ConfirmSwapModal'
import { ButtonPrimary } from '../Button'
import { AutoRow, RowBetween } from '../Row'
import { ThemedText } from 'theme/components'
import Loader from '../Icons/LoadingSpinner'
import { AutoColumn } from '../Column'
import { formatUnits, parseUnits } from 'ethers/lib/utils'
import { useFormatter } from 'utils/formatNumbers'
import { SwapModal } from '../ConfirmSwapModal/Modal'
import { NumberType } from 'utils/formatNumbers'
import Slider from '../Slider'
import { ThemeProvider } from 'theme'
import styled from 'styled-components'
import { useClientSideV3Trade } from 'state/routing/useClientSideV3Trade'
import { InterfaceTrade, TradeResults, TradeState } from 'state/routing/types'
import { getAavePoolAddressProvider, getUiPoolDataProviderV3 } from 'constants/contracts/helpers'
import { useWeb3React } from '@web3-react/core'
import { useContract } from 'hooks/useContract'
import UiPoolDataProviderV3ABI from '../../constants/contracts/abis/UiPoolDataProviderV3.json';
import { useUSDPrice } from 'hooks/useUSDPrice'
import { CurrencyAmount, Percent, Token, TradeType } from '@uniswap/sdk-core'
import { BigNumber } from 'ethers'
import ERC20_ABI from 'uniswap/src/abis/erc20.json'
import { useAppSelector } from 'state/hooks'
import { useTokenData } from 'state/assetData/hooks'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { SwapPreview } from 'components/swap/SwapPreview'
import { ClosePreview } from './ClosePreview'
import { Box } from 'pages/Landing/components/Generics'
import { useEditPosition } from 'hooks/useEditPosition'
import { FadePresence } from 'theme/components/FadePresence'
import { Pending } from 'components/ConfirmSwapModal/Pending'
import { AllowanceState } from 'hooks/usePermit2Allowance'


const Container = styled.div<{ $height?: string; $padding?: string }>`
  height: ${({ $height }) => $height ?? ""};
  padding: ${({ $padding }) => $padding ?? ""};
`;

export function ConfirmEditPositionModal({
  position,
  onDismiss,
}: {
  position: any // TODO: fix this
  onDismiss: () => void
}) {
  const [confirmModalState, setConfirmModalState] = useState<ConfirmModalState>(ConfirmModalState.REVIEWING)
  const [closePercentage, setClosePercentage] = useState(100)
  // const { closePosition, isLoading, error } = useClosePosition()
  const { formatNumber } = useFormatter()

  const [debtTokenAmount, setDebtTokenAmount] = useState<CurrencyAmount<Token> | null>(null)
  const [isLoadingDebtAmount, setIsLoadingDebtAmount] = useState(true)

  const { closePosition, error } = useEditPosition(position.poolAddress)

  const chainId = position.token1.chainId
  
  const tokensData = useAppSelector((state) => state.assetState.tokens)

  const debtData = useMemo(() => {
    if(Object.keys(tokensData).length === 0) return null
    return tokensData[chainId]['Aave'][position.token1.address]
  }, [chainId, tokensData])

  const collateralData = useMemo(() => {
    if(Object.keys(tokensData).length === 0) return null
    return tokensData[chainId]['Aave'][position.token0.address]
  }, [chainId, tokensData])


  const positionData = useAppSelector((state) => state.assetState.positions[chainId].find((key: any) => key.poolAddress === position.poolAddress))
  
  // TODO: the debtAmount needs to be pro-rata to user ownership
  const debtAmount = useMemo(() => {
    if (!positionData?.stats?.totalDebtBase || !debtData?.priceInMarketReferenceCurrency) {
      return '0'
    }
    return (Number(positionData.stats.totalDebtBase) / Number(debtData.priceInMarketReferenceCurrency)).toString()
  }, [positionData, debtData])

  // TODO: the collatealAmount needs to be pro-rata to user ownership
  const collateralAmount = useMemo(() => {
    if (!positionData?.stats?.totalCollateralBase || !collateralData?.priceInMarketReferenceCurrency) {
      return '0'
    }
    return (Number(positionData.stats.totalCollateralBase) / Number(collateralData.priceInMarketReferenceCurrency)).toString()
  }, [positionData, debtData])


const parsedAmount = useMemo(() => {
    // Use the modified amount in your tryParseCurrencyAmount function
    return tryParseCurrencyAmount(
      debtAmount.toString(),
      position.token0 ? position.token0 : undefined
    );
  }, [position.token0, position.token1, debtAmount]);

  const parsedReceiveAmount = useMemo(() => {
    // Use the modified amount in your tryParseCurrencyAmount function
    return tryParseCurrencyAmount(
      collateralAmount.toString(),
      position.token0 ? position.token0 : undefined
    );
  }, [position.token1, position.token0, debtAmount]);
  
  const trade: {
    state: TradeState;
    trade?: InterfaceTrade;
    swapQuoteLatency?: number;
  } = useClientSideV3Trade(
    
    TradeType.EXACT_OUTPUT,
    // This is the amount we need to get from the DEX (often inputCurrency or collateral)
    parsedAmount, //debounceTradeOutputAmount,
    // This is the amount and current we pay the DEX (often debt)
    // outputCurrency ? outputCurrency : undefined
    position.token1 ? position.token1 : undefined
    //(isExactIn ? outputCurrency : inputCurrency) ?? undefined
  );

  console.log('trade', trade)

  const data = useMemo(() => {
    if (!trade.trade || !position.token0 || !position.token1) return null
    return {
      trade: trade.trade,
      inputCurrency: position.token0,
      outputCurrency: position.token1,
      closePercentage: closePercentage
    }
  }, [trade, position.token0, position.token1])
  

  const handleClose = useCallback(async () => {
    setConfirmModalState(ConfirmModalState.PENDING_CONFIRMATION)
    try {
      if (trade.state !== TradeState.VALID || !trade.trade) {
        throw new Error('No valid trade found')
      }
      if (!positionData?.userStats) {
        throw new Error('No position data found')
      }
      const amountToClose = positionData.userStats.userBalance
      await closePosition(amountToClose, trade.trade)
      setConfirmModalState(ConfirmModalState.REVIEWING)
      onDismiss()
    } catch (error) {
      console.error('Failed to close position:', error)
      setConfirmModalState(ConfirmModalState.REVIEWING)
    }
  }, [closePosition, position, trade, closePercentage, onDismiss])

  const positionDetails = useMemo(() => {
    
    return (
      <>

        {data && (
        <ClosePreview
          trade={trade.trade!}
          inputCurrency={position.token0}
          allowedSlippage={new Percent(50, 10000)}
          tradeResults={new TradeResults({
            payAmount: parsedAmount!,
            receiveAmount: parsedReceiveAmount!,
          })}
        />
        )}
        {trade.state === TradeState.LOADING && 
        <Box justify="center">
        <Loader size="32px" />
        </Box>}
        
      </>
  )}, [closePosition, positionData, closePercentage, trade, onDismiss])

  const modalContent = useMemo(() => {
    if (confirmModalState === ConfirmModalState.PENDING_CONFIRMATION) {
      return (    
          <Container>
            <FadePresence>
              <Pending
                trade={trade.trade}
                // swapResult={swapResult}
                // wrapTxHash={wrapTxHash}
                // tokenApprovalPending={
                //   allowance.state === AllowanceState.REQUIRED &&
                //   allowance.isApprovalPending
                // }
                // revocationPending={
                //   allowance.state === AllowanceState.REQUIRED &&
                //   allowance.isRevocationPending
                // }
              />
            </FadePresence>
          </Container>
        
      )
    }

    return (
      <>
        <ThemedText.HeadlineSmall style={{ marginBottom: '16px' }}>
          <Trans>Close position</Trans>
        </ThemedText.HeadlineSmall>
        {positionDetails}
        {/* <AutoColumn gap="12px" style={{ marginTop: '16px' }}>
          <ThemedText.BodyPrimary>Close {closePercentage}% of your position</ThemedText.BodyPrimary>
          <Slider
            value={closePercentage}
            onChange={setClosePercentage}
            step={1}
            min={0}
            max={100}
          />
        </AutoColumn> */}
        <ButtonPrimary onClick={handleClose} disabled={false} style={{ marginTop: '16px' }}>
           <Trans>Close</Trans>
        </ButtonPrimary>
      </>
    )
  }, [confirmModalState, position, closePercentage, handleClose, positionDetails])

  return (
    <ThemeProvider>
      <SwapModal
        onDismiss={onDismiss}
        confirmModalState={confirmModalState}
      >
    
        {modalContent}
      </SwapModal>
    </ThemeProvider>
  )
}