import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PositionData, AssetState, TokenData, UserSharesData } from './types'

//** Initial state
const initialState: AssetState = {
  positions: { lastUpdated: 0 },
  tokens: { lastUpdated: 0 },
  loading: {},
  errors: {}
}

const assetSlice = createSlice({
  name: 'asset',
  initialState,
  reducers: {
    //** Position data actions
    updatePositionData: (
      state,
      action: PayloadAction<{ chainId: number; data: PositionData[] }>
    ) => {
      const { chainId, data } = action.payload
      state.positions[chainId] = data
      state.positions['lastUpdated'] = Date.now()
      state.loading[chainId] = false
      state.errors[chainId] = null
    },

    updateUserSharesData: (
      state,
      action: PayloadAction<{ chainId: number; data: UserSharesData[] }>
    ) => {
      const { chainId, data } = action.payload
      state.loading[chainId] = false
      // TODO: Implement user shares data update logic
      state.errors[chainId] = null
    },

    //** Token data actions
    updateTokenData: (
      state,
      action: PayloadAction<{ chainId: number; data: TokenData }>
    ) => {
      const { chainId, data } = action.payload
      state.tokens[chainId] = data
      state.tokens['lastUpdated'] = Date.now()
      state.loading[chainId] = false
      state.errors[chainId] = null
    },

    //** Loading state actions
    setLoading: (
      state,
      action: PayloadAction<{ chainId: number; isLoading: boolean }>
    ) => {
      const { chainId, isLoading } = action.payload
      state.loading[chainId] = isLoading
    },

    //** Error handling actions
    setPositionDataError: (
      state,
      action: PayloadAction<{ chainId: number; error: string }>
    ) => {
      const { chainId, error } = action.payload
      state.errors[chainId] = error
      state.loading[chainId] = false
    },

    //** Reset action
    resetState: () => initialState
  }
})

//** Export actions
export const {
  updatePositionData,
  updateUserSharesData,
  updateTokenData,
  setLoading,
  setPositionDataError,
  resetState
} = assetSlice.actions

//** Export reducer
export default assetSlice.reducer
