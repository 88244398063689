import { useCallback, useEffect, useRef } from 'react'
import { useAppSelector, useAppDispatch } from '../hooks'
import {
  resetState,
  updatePositionData,
  setPositionDataError,
  setLoading,
  updateTokenData
} from './reducer'
import { AssetState, PositionData, TokenData, UserSharesData } from './types'
import LekkerLensABI from 'uniswap/src/abis/LekkerLens.json'
import {
  getAavePoolAddress,
  getAavePoolAddressProvider,
  getLekkerFactory,
  getLensContract,
  getUiPoolDataProviderV3
} from 'constants/contracts/helpers'
import { useContract } from 'hooks/useContract'
import UiPoolDataProviderV3ABI from '../../constants/contracts/abis/UiPoolDataProviderV3.json'
import { useAccount } from 'wagmi'

export function useAssetState (): AssetState {
  return useAppSelector(state => state.assetState)
}

export function usePositionData (chainId: number) {
  return useAppSelector(state => state.assetState.positions[chainId])
}

export function usePositionDataError (chainId: number) {
  return useAppSelector(state => state.assetState.errors[chainId])
}

export function useTokenData (chainId: number) {
  const dispatch = useAppDispatch()
  const tokenData = useAppSelector(state => state.assetState.tokens)
  const isLoading = useAppSelector(state => state.assetState.loading[chainId])
  const lastFetchRef = useRef<number>(0)
  const hasFetchedRef = useRef<boolean>(false)
  const uiPoolDataProviderAddress = getUiPoolDataProviderV3(chainId)
  const aavePoolAddressProvideAddress = getAavePoolAddressProvider(chainId)
  const uiPoolDataProviderContract = useContract(
    uiPoolDataProviderAddress,
    UiPoolDataProviderV3ABI
  )
  const { address } = useAccount()

  useEffect(() => {
    if (!hasFetchedRef.current && !isLoading) {
      const fetchTokenData = async () => {
        const now = Date.now()
        if (
          now - lastFetchRef.current < 60000 ||
          tokenData.lastUpdated ||
          isLoading
        ) {
          console.log(
            'Skipping token data fetch, last fetch was recent or already loading'
          )
          return
        }
        console.log('Proceeding to fetch token data')

        try {
          dispatch(setLoading({ chainId, isLoading: true }))
          lastFetchRef.current = now
          console.log('Fetching token data...', chainId)

          if (!uiPoolDataProviderContract) {
            throw new Error('UiPoolDataProviderContract is not initialized')
          }

          // TODO: fetching without account connected throws an error
          if (!address) return

          const [reservesData, baseCurrencyInfo] =
            await uiPoolDataProviderContract.getReservesData(
              aavePoolAddressProvideAddress
            )

          const tokenData: TokenData = {
            Aave: reservesData.reduce((acc: any, reserve: any) => {
              acc[reserve.underlyingAsset] = {
                name: reserve.name,
                symbol: reserve.symbol,
                decimals: reserve.decimals.toString(),
                baseLTVasCollateral: reserve.baseLTVasCollateral.toString(),
                reserveLiquidationThreshold:
                  reserve.reserveLiquidationThreshold.toString(),
                reserveLiquidationBonus:
                  reserve.reserveLiquidationBonus.toString(),
                reserveFactor: reserve.reserveFactor.toString(),
                usageAsCollateralEnabled: reserve.usageAsCollateralEnabled,
                borrowingEnabled: reserve.borrowingEnabled,
                stableBorrowRateEnabled: reserve.stableBorrowRateEnabled,
                isActive: reserve.isActive,
                isFrozen: reserve.isFrozen,
                liquidityIndex: reserve.liquidityIndex.toString(),
                variableBorrowIndex: reserve.variableBorrowIndex.toString(),
                liquidityRate: reserve.liquidityRate.toString(),
                variableBorrowRate: reserve.variableBorrowRate.toString(),
                stableBorrowRate: reserve.stableBorrowRate.toString(),
                lastUpdateTimestamp: reserve.lastUpdateTimestamp.toString(),
                aTokenAddress: reserve.aTokenAddress,
                stableDebtTokenAddress: reserve.stableDebtTokenAddress,
                variableDebtTokenAddress: reserve.variableDebtTokenAddress,
                interestRateStrategyAddress:
                  reserve.interestRateStrategyAddress,
                availableLiquidity: reserve.availableLiquidity.toString(),
                totalPrincipalStableDebt:
                  reserve.totalPrincipalStableDebt.toString(),
                averageStableRate: reserve.averageStableRate.toString(),
                stableDebtLastUpdateTimestamp:
                  reserve.stableDebtLastUpdateTimestamp.toString(),
                totalScaledVariableDebt:
                  reserve.totalScaledVariableDebt.toString(),
                priceInMarketReferenceCurrency:
                  reserve.priceInMarketReferenceCurrency.toString(),
                variableRateSlope1: reserve.variableRateSlope1.toString(),
                variableRateSlope2: reserve.variableRateSlope2.toString(),
                stableRateSlope1: reserve.stableRateSlope1.toString(),
                stableRateSlope2: reserve.stableRateSlope2.toString(),
                isPaused: reserve.isPaused,
                debtCeiling: reserve.debtCeiling.toString(),
                debtCeilingDecimals: reserve.debtCeilingDecimals.toString(),
                borrowCap: reserve.borrowCap.toString(),
                supplyCap: reserve.supplyCap.toString(),
                eModeLtv: reserve.eModeLtv.toString(),
                eModeLiquidationThreshold:
                  reserve.eModeLiquidationThreshold.toString(),
                eModeLiquidationBonus: reserve.eModeLiquidationBonus.toString(),
                eModePriceSource: reserve.eModePriceSource,
                eModeLabel: reserve.eModeLabel,
                borrowableInIsolation: reserve.borrowableInIsolation,
                // New fields
                accruedToTreasury: reserve.accruedToTreasury.toString(),
                baseStableBorrowRate: reserve.baseStableBorrowRate.toString(),
                baseVariableBorrowRate:
                  reserve.baseVariableBorrowRate.toString(),
                eModeCategoryId: reserve.eModeCategoryId,
                flashLoanEnabled: reserve.flashLoanEnabled,
                isSiloedBorrowing: reserve.isSiloedBorrowing,
                isolationModeTotalDebt:
                  reserve.isolationModeTotalDebt.toString(),
                optimalUsageRatio: reserve.optimalUsageRatio.toString(),
                priceOracle: reserve.priceOracle,
                unbacked: reserve.unbacked.toString(),
                virtualAccActive: reserve.virtualAccActive,
                virtualUnderlyingBalance:
                  reserve.virtualUnderlyingBalance.toString()
              }
              return acc
            }, {})
          }

          dispatch(updateTokenData({ chainId, data: tokenData }))
          dispatch(setLoading({ chainId, isLoading: false }))
        } catch (error) {
          console.error('Error fetching token data:', error)
          console.error('Error details: ', {
            uiPoolDataProviderAddress,
            aavePoolAddressProvideAddress
          })
          dispatch(setPositionDataError({ chainId, error: error.message }))
        } finally {
          dispatch(setLoading({ chainId, isLoading: false }))
        }
      }

      fetchTokenData()
      hasFetchedRef.current = true
    }
  }, [
    // TODO: review if we should do all these dependencies
    dispatch,
    chainId,
    uiPoolDataProviderAddress,
    aavePoolAddressProvideAddress,
    isLoading
  ])
}

export function useAssetActions (chainId: number) {
  const dispatch = useAppDispatch()
  const positionData = useAppSelector(state => state.assetState.positions)
  const isLoading = useAppSelector(state => state.assetState.loading[chainId])
  const lastFetchRef = useRef<number>(0)
  const hasFetchedRef = useRef<boolean>(false)
  const lensContractAddress = getLensContract(chainId)
  const lekkerFactoryAddress = getLekkerFactory(chainId)
  const aavePoolAddress = getAavePoolAddress(chainId)
  //TODO: we should use with false, and use a premium RPC
  const lensContract = useContract(lensContractAddress, LekkerLensABI)
  const { address } = useAccount()

  useEffect(() => {
    if (!hasFetchedRef.current && !isLoading) {
      const fetchPositionData = async () => {
        const now = Date.now()
        if (
          now - lastFetchRef.current < 60000 ||
          positionData.lastUpdated ||
          isLoading
        ) {
          console.log(
            'Skipping position data fetch, last fetch was recent or already loading'
          )
          return
        }

        try {
          dispatch(setLoading({ chainId, isLoading: true }))
          lastFetchRef.current = now

          if (!lensContract) {
            throw new Error('LensContract is not initialized')
          }

          if (!address) return

          // TODO: we need to get all data, not just aave
          let data = await lensContract.getAavePoolData(
            lekkerFactoryAddress,
            aavePoolAddress
          )
          data = [...data].reverse()

          // TODO: these are old pools with collateral but no debt - we need to exclude them for now
          // and addToPosition eventually so we fix them.
          const excludedPoolAddresses = [
            '0xf5D7817ed16c1EcC1Ec2a2a9D9AE64561e3232Ba', // Replace with actual addresses
            '0xFf2EFcDE6368e0aB2B36609A1Cf0F2B5E0b97cE4',
            '0x70E59EC5b2cb213023a74441Fb5B67aB4c80C5EE',
            '0x645e31244564Ec62b057e407E8f1F343bC34D169'
          ]

          const result = data
            .filter(
              (item: any) =>
                !excludedPoolAddresses.includes(item.lekkerPoolAddress)
            )
            .map((entry: any) => ({
              poolAddress: entry.lekkerPoolAddress,
              collateral: entry.collateral,
              debt: entry.debt,
              lender: 'Aave',
              stats: {
                totalCollateralBase: entry.totalCollateralBase.toString(),
                totalDebtBase: entry.totalDebtBase.toString(),
                availableBorrowsBase: entry.availableBorrowsBase.toString(),
                currentLiquidationThreshold:
                  entry.currentLiquidationThreshold.toString(),
                ltv: entry.ltv.toString(),
                currentLtv: entry.totalCollateralBase.isZero()
                  ? '0'
                  : Math.floor(
                      (entry.totalDebtBase * 10000) / entry.totalCollateralBase
                    ).toString(),
                healthFactor: entry.healthFactor.toString(),
                leverage: entry.totalDebtBase.isZero()
                  ? '1'
                  : Math.floor(
                      (entry.totalCollateralBase * 10) /
                        (entry.totalCollateralBase - entry.totalDebtBase)
                    ).toString()
              }
            }))
            .filter(
              (item: any) => !excludedPoolAddresses.includes(item.poolAddress)
            )

          // Fetch user shares data if account is known
          if (address) {
            let userSharesData: UserSharesData[] =
              await lensContract.getUserShares(lekkerFactoryAddress, address)
            const filteredUserSharesData = userSharesData.filter(
              (entry: any) => entry.userBalance.toString() !== '0'
            )

            // Combine user shares data with Aave pool data
            filteredUserSharesData.forEach(userShare => {
              const poolIndex = result.findIndex(
                (pool: any) => pool.poolAddress === userShare.lekkerPoolAddress
              )
              if (poolIndex !== -1) {
                result[poolIndex].userStats = {
                  userShares: userShare.userShares.toString(),
                  userBalance: userShare.userBalance.toString(),
                  totalSupply: userShare.totalSupply.toString()
                }
              }
            })
          }

          dispatch(updatePositionData({ chainId, data: result }))
          dispatch(setLoading({ chainId, isLoading: false }))
        } catch (error) {
          console.error('Error fetching position data:', error)
          console.error('Error details:', {
            chainId,
            address,
            lensContractAddress,
            lekkerFactoryAddress,
            aavePoolAddress
          })
          dispatch(setPositionDataError({ chainId, error: error.message }))
        } finally {
          dispatch(setLoading({ chainId, isLoading: false }))
        }
      }

      fetchPositionData()
      hasFetchedRef.current = true
    }
  }, [
    dispatch,
    chainId,
    lensContractAddress,
    lekkerFactoryAddress,
    aavePoolAddress,
    address,
    isLoading
  ])

  // return {
  //   updatePositionData: (chainId: number, data: PositionData[]) =>
  //     dispatch(updatePositionData({ chainId, data })),

  //   setAaveDataError: (chainId: number, error: string) =>
  //     dispatch(setPositionDataError({ chainId, error })),

  //   resetAssetState: () => dispatch(resetState()),

  // }
}
